import React, { useEffect, useState } from "react";
import AlternativeHeader from "components/Headers/AlternativeHeader";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Modal,
  Row,
  Spinner,
} from "reactstrap";
import DataTable from "react-data-table-component";
import { getPanicEvents, exportPanicEvents } from "services/services";
import { useHistory } from "react-router";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { toast } from "react-toastify";
import { CSVLink } from "react-csv";
import "./panicModal.css";

export default function PanicEvent() {
  const [perPage, setPerPage] = useState(10);
  const [pageNo, setPageNo] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [loading, setLoading] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [contactList, setcontactList] = useState("");
  // const [selectStatus, setSelectStatus] = useState('');
  const [selectOrderBy, setSelectOrderBy] = useState("date");
  const [selectOrder, setSelectOrder] = useState("DESC");
  const [userId, setUserId] = useState("");
  const [userStatus, setUserStatus] = useState(false);
  const [alert, setAlert] = useState(null);
  const [exportPanicEvent, setexportPanicEvent] = useState("");

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const [logsData, setLogsData] = useState([]);

  const history = useHistory();

  useEffect(() => {
    console.log("let see change", searchInput);
    let params = {
      limit: perPage,
      page_no: pageNo,
      order: selectOrder,
      keyword: searchInput,
      order_by: selectOrderBy,
    };

    setLoading(true);
    getAllContact(params);

    let dataContact = {
      order: selectOrder,
      keyword: searchInput,
      order_by: selectOrderBy,
    };
    exportContact(dataContact);
  }, []);

  //   const onEdit = (userId) => {
  //     history.push({
  //       pathname: "/admin/customerdetails/" + userId,
  //     });
  //   };

  const onLogsClick = (rowData) => {
    setLogsData(rowData?.alertLogs);
    toggle();
  };

  let count = 1;

  const columns = [
    {
      name: "S. No.",
      maxWidth: "70px",
      selector: (row, index) => (pageNo - 1) * perPage + index + 1,
      sortable: true,
    },
    {
      name: "User Full name",
      maxWidth: "150px",
      selector: (row) => row?.element?.userAlert?.fullName,
      sortable: true,
      compact: true,
    },
    {
      name: "UserName",
      maxWidth: "100px",
      selector: (row) => row?.element?.userAlert?.userName,
      sortable: true,
    },
    {
      maxWidth: "250px",
      name: "Date & Time of the Panic Event",
      selector: (row) => new Date(row?.element?.createdAt).toLocaleString(),
      sortable: true,
    },

    {
      name: "Event URL",
      selector: (row) => (
        <a href={row?.eventUrl} style={{ color: "blue" }} target="_blank">
          {row?.eventUrl}
        </a>
      ),
      sortable: true,
      compact: true,
    },
    {
      center: true,
      name: "Logs",
      maxWidth: "120px",
      cell: (row) => {
        if (row?.element?.alertLogs !== "") {
          return (
            <div className="d-flex justify-content-center">
              <div
                // className="mr-2 btn-primary"
                onClick={() => onLogsClick(row?.element)}
                style={{width: "100px", color: 'blue', fontSize: '15px', textDecoration: 'underline', cursor: 'pointer'}}
              >
                View logs
              </div>
            </div>
          );
        }
      },
    },
    // {
    //   name: "Created Date",
    //   maxWidth: "200px",
    //   center: true,
    //   selector: (row) => row.createdDate,
    //   sortable: true,
    //   cell: (row) => {
    //     if (row && row.createdDate) {
    //       return (
    //         <div className="d-flex justify-content-center">
    //           {new Date(row.createdDate).toLocaleDateString("en-GB", {
    //             year: "numeric",
    //             month: "short",
    //             day: "numeric",
    //           })}
    //         </div>
    //       );
    //     }
    //   },
    // },
    // {
    //   name: "Register via",
    //   maxWidth: "150px",
    //   center: true,
    //   selector: (row) => row.accountType,
    //   sortable: true,
    //   cell: (row) => {
    //     if (row.accountType === 1) return "EMAIL";
    //     else if (row.accountType === 2) return "FACEBOOK";
    //     else if (row.accountType === 3) return "GOOGLE";
    //     else return "APPLE";
    //   },
    // },
    // {
    //   maxWidth: "150px",
    //   center: true,
    //   name: "Status",
    //   selector: (row) => row?.isDeleted,
    //   cell: (row) => {
    //     return (
    //       <div>
    //         {row?.isDeleted === 1 ? (
    //           <label className="custom-toggle custom-toggle-success">
    //             <input
    //               defaultChecked
    //               type="checkbox"
    //               onChange={() => activateUser(row.userId, row.status)}
    //             />
    //             <span className="custom-toggle-slider rounded-circle" />
    //           </label>
    //         ) : (
    //           <label className="custom-toggle custom-toggle-success">
    //             <input
    //               type="checkbox"
    //               onChange={() => deactivateUser(row.userId, row.status)}
    //             />
    //             <span className="custom-toggle-slider rounded-circle" />
    //           </label>
    //         )}
    //       </div>
    //     );
    //   },
    // },
    // {
    //   center: true,
    //   name: "Action",
    //   maxWidth: "120px",
    //   cell: (row) => {
    //     return (
    //       <div className="d-flex justify-content-center">
    //         <>
    //           <Button
    //             className="mr-2 btn-primary"
    //             onClick={() => onEdit(row.userId)}
    //           >
    //             <i className="fa fa-edit" />
    //           </Button>
    //         </>
    //       </div>
    //     );
    //   },
    // },
  ];

  const getAllContact = async (params) => {
    setLoading(true);
    try {
      let response = await getPanicEvents(params);
      console.log("search ", response.data.data[0]);
      if (response && response.status === 200) {
        setcontactList(response.data.data);
        setTotalRows(response.data.totalResult);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      setcontactList([]);
    }
  };

  const onSearch = async (e) => {
    console.log("here change ", e.target.value);
    setSearchInput(e.target.value);
    setPageNo(1);
    let dataContact = {
      order: selectOrder,
      keyword: e.target.value,
      order_by: selectOrderBy,
    };
    exportContact(dataContact);

    if (e.target.value.length > 2) {
      let params = {
        limit: perPage,
        page_no: 1,
        order: selectOrder,
        keyword: e.target.value,
        order_by: selectOrderBy,
      };
      getAllContact(params);
    } else {
      let params = {
        limit: perPage,
        page_no: 1,
        order: selectOrder,
        keyword: e.target.value,
        order_by: selectOrderBy,
      };
      getAllContact(params);
    }
  };

  const handlePageChange = (page) => {
    setLoading(true);
    let params = {
      limit: perPage,
      page_no: page,
      order: selectOrder,
      keyword: searchInput,
      order_by: selectOrderBy,
    };
    setPageNo(page);
    getAllContact(params);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    let params = {
      limit: newPerPage,
      page_no: page,
      order: selectOrder,
      keyword: searchInput,
      order_by: selectOrderBy,
    };
    getAllContact(params);
    setPerPage(newPerPage);
  };

  const handleSelect = (e) => {
    setPageNo(1);
    setSelectOrderBy(e.target.value);
    let params = {
      limit: perPage,
      page_no: 1,
      order: selectOrder,
      keyword: searchInput,
      order_by: e.target.value,
    };
    getAllContact(params);
    let dataContact = {
      order: selectOrder,
      keyword: searchInput,
      order_by: e.target.value,
    };
    exportContact(dataContact);
  };

  const handleSelectOrder = (e) => {
    setSelectOrder(e.target.value);
    let params = {
      limit: perPage,
      page_no: pageNo,
      order: e.target.value,
      keyword: searchInput,
      order_by: selectOrderBy,
    };
    getAllContact(params);
    let dataContact = {
      order: e.target.value,
      keyword: searchInput,
      order_by: selectOrderBy,
    };
    exportContact(dataContact);
  };

  //   const activateUser = (userId, status) => {
  //     // setShowAlert(true);
  //     setUserId(userId);
  //     setUserStatus(true);
  //     setAlert(
  //       <ReactBSAlert
  //         warning
  //         style={{ display: "block", marginTop: "-100px" }}
  //         title="Are you sure?"
  //         onConfirm={() => onConfirmation(userId, status)}
  //         showCancel
  //         onCancel={() => onCancel()}
  //         confirmBtnBsStyle="secondary"
  //         confirmBtnText="Yes"
  //         cancelBtnBsStyle="danger"
  //         cancelBtnText="Cancel"
  //       >
  //         {status === 0
  //           ? "You want to activate this User!"
  //           : "You want to deactivate this User!"}
  //       </ReactBSAlert>
  //     );
  //   };

  //   const deactivateUser = (userId, status) => {
  //     // setShowAlert(true);
  //     setUserId(userId);
  //     setUserStatus(false);
  //     setAlert(
  //       <ReactBSAlert
  //         warning
  //         style={{ display: "block", marginTop: "-100px" }}
  //         title="Are you sure?"
  //         onConfirm={() => onConfirmation(userId, status)}
  //         showCancel
  //         onCancel={() => onCancel()}
  //         confirmBtnBsStyle="secondary"
  //         confirmBtnText="Yes"
  //         cancelBtnBsStyle="danger"
  //         cancelBtnText="Cancel"
  //       >
  //         {status === 0
  //           ? "You want to activate this User!"
  //           : "You want to deactivate this User!"}
  //       </ReactBSAlert>
  //     );
  //   };

  //   const onCancel = () => {
  //     let params = {
  //       limit: perPage,
  //       page_no: 1,
  //       role_id: 6,
  //       search: searchInput,
  //     };
  //     setAlert(null);
  //     setLoading(true);
  //     getAllContact(params);
  //   };

  //   const onConfirmation = async (userId, status) => {
  //     let admin_status = status === 1 ? 0 : 1;
  //     let data = { status: admin_status };
  //     let success_msg =
  //       status === 0
  //         ? "User activated successfully."
  //         : "User deactivated successfully.";
  //     // const id = userId;

  //     try {
  //       let res = await updateUserStatus(userId, data);
  //       if (res && res.status === 200) {
  //         toast.success(success_msg, {
  //           position: toast.POSITION.TOP_RIGHT,
  //           autoClose: 2000,
  //         });
  //         let params = {
  //           limit: perPage,
  //           page_no: 1,
  //           role_id: 6,
  //           search: searchInput,
  //         };
  //         setLoading(true);
  //         setTimeout(() => {
  //           getAllContact(params);
  //         }, 2000);
  //       }
  //     } catch (error) {
  //       if (error && error.response && error.response.status !== 500) {
  //         toast.error(error.response.data.message, {
  //           position: toast.POSITION.TOP_RIGHT,
  //           autoClose: 3000,
  //         });
  //       } else {
  //         toast.error("Oops something went wrong...", {
  //           position: toast.POSITION.TOP_RIGHT,
  //           autoClose: 3000,
  //         });
  //       }
  //     }

  //     setAlert(null);
  //   };

  //   const addCustomer = () => {
  //     let path = `/admin/customerdetails/add`;
  //     history.push(path);
  //   };

  const exportContact = async (dataContact) => {
    try {
      let response = await exportPanicEvents(dataContact);
      console.log("here res ", response);
      if (response && response.status === 200) {
        setexportPanicEvent(response.data);
      }
    } catch (err) {
      setLoading(false);
    }
  };

  const columns2 = [
    {
      name: "Event Type",
      minWidth: "150px",
      maxWidth: "100px",
      selector: (row) => row?.eventType,
      sortable: true,
      compact: true,
    },
    {
      name: "Event Message",
      minWidth: "250px",
      maxWidth: "700px",
      selector: (row) => row?.eventMessage,
      sortable: true,
    },
    {
      maxWidth: "200px",
      name: "Log Date & Time",
      selector: (row) => row?.timeStamp,
      sortable: true,
    },
  ];

  return (
    <>
      {alert}
      <AlternativeHeader pageTitle="Panic Events" parentComponent="" />

      <Modal
        className="modal-dialog-centered custom-modal lg"
        isOpen={modal}
        toggle={toggle}
        modalTransition={{ timeout: 100 }}
        scrollable={true}
        size='xl'
      >
        <div className="modal-header">
          <h5 className="modal-title">Event Logs</h5>
          <button
            type="button"
            className="btn-close"
            aria-label="Close"
            onClick={toggle}
          >
            <span aria-hidden={true} style={{ fontSize: "20px" }}>
              ×
            </span>
          </button>
        </div>
        <DataTable columns={columns2} data={logsData} striped />
      </Modal>
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <h3 className="mb-0">Panic Events</h3>
              </CardHeader>
              <CardBody>
                <Row className="mb-3">
                  <Col md="3">
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="fa fa-search" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Search"
                        name="searchInput"
                        type="text"
                        onChange={onSearch}
                        autoComplete="off"
                        value={searchInput || ""}
                      />
                    </InputGroup>
                  </Col>
                  <Col md="9">
                    <div className="d-flex justify-content-end align-items-center flex-wrap">
                      <Row className="mb-1">
                        <Col md="4">
                          <Input
                            type="select"
                            name="selectOrderBy"
                            onChange={handleSelect}
                            id="exampleSelect"
                          >
                            <option value="date">Date</option>
                            <option value="username">User name</option>
                            <option value="fullName">Full name</option>
                          </Input>
                        </Col>
                        <Col md="4">
                          <Input
                            type="select"
                            name="selectOrder"
                            onChange={handleSelectOrder}
                            id="exampleSelect"
                          >
                            <option value="DESC">Descending</option>
                            <option value="ASC">Ascending</option>
                          </Input>
                        </Col>
                        <Col md="4">
                          <CSVLink
                            data={exportPanicEvent}
                            filename={"PanicEvent.csv"}
                            className="btn btn-info"
                            // onClick={exportContact}
                          >
                            Export
                          </CSVLink>
                          {/* <Button type="button" className="btn btn-info mx-2" onClick={exportContact}> 
                          </Button> */}
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
                <DataTable
                  columns={columns}
                  data={contactList}
                  progressPending={loading}
                  progressComponent={
                    <Spinner
                      as="span"
                      size="lg"
                      role="status"
                      aria-hidden="true"
                    />
                  }
                  pagination
                  paginationServer
                  paginationTotalRows={totalRows}
                  onChangeRowsPerPage={handlePerRowsChange}
                  onChangePage={handlePageChange}
                  paginationRowsPerPageOptions={[10, 25, 50, 75, 100]}
                  striped
                />
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}
